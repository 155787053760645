import scrollLock from '../functions/_scrollLock.js' // スクロールロック
const spMenuTrigger = document.querySelectorAll('#gNav-open,.gNav-close,#gNav a,#overlay')
const spMenuTarget = document.querySelectorAll('#gNav-open,#gNav,#overlay,#header')
const spNav = document.getElementById('gNav')
if (spNav) {
  // SPナビのdisplay:noneを取り除く
  document.getElementById('gNav').style.display = ''
  for (const trigger of spMenuTrigger) {
    trigger.addEventListener(
      'click',
      function () {
        // 押した箇所の.activeの有無やタグ種別を見て分岐
        if (!trigger.classList.contains('active') && trigger.tagName !== 'A') {
          for (const target of spMenuTarget) {
            target.classList.add('active')
          }
        } else {
          for (const target of spMenuTarget) {
            target.classList.remove('active')
          }
        }
        scrollLock('body', '#overlay')
      },
      false
    )
  }
}
