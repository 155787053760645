import $ from 'jquery' // jQuery
import './module/_hashScroll' // アンカーリンクスクロール
import './module/_spMenuToggle' // SPメニュー開閉
/* global  */ // tUrl等のグローバル変数を宣言する
import './_top.js'
// import './_form.js'

const shuffleNumberCounter = (target) => {
  const targetNum = Number(target.getAttribute('data-num'))

  if (!targetNum) {
    return
  }

  let counterData = null
  const speed = 2900 / targetNum
  let initNum = 0

  const countUp = () => {
    if (Number.isInteger(targetNum)) {
      target.innerHTML = initNum
    } else {
      target.innerHTML = `${initNum}.${Math.floor(Math.random() * 9)}`
    }

    initNum++

    if (initNum > targetNum) {
      target.innerHTML = targetNum
      clearInterval(counterData)
    }
  }

  counterData = setInterval(countUp, speed)
}

const target = document.querySelector('.number')

shuffleNumberCounter(target)

// import { Autoplay, Controller, EffectFade, Navigation, Pagination, Swiper } from 'swiper'
// Swiper.use([Autoplay, Controller, EffectFade, Navigation, Pagination]) // Swiper宣言
// その他、Swiperで使う機能があったら上に追加で宣言する
// A11y, Autoplay, Controller, EffectCoverflow, EffectCube, EffectFade, EffectFlip, HashNavigation, History, Keyboard, Lazy, Mousewheel, Navigation, Pagination, Parallax, Scrollbar, Thumbs, Virtual, Zoom,
