// import { Autoplay, Controller, EffectFade, Navigation, Pagination, Swiper } from 'swiper'
// Swiper.use([Autoplay, Controller, EffectFade, Navigation, Pagination]) // Swiper宣言
// その他、Swiperで使う機能があったら上に追加で宣言する
// A11y, Autoplay, Controller, EffectCoverflow, EffectCube, EffectFade, EffectFlip, HashNavigation, History, Keyboard, Lazy, Mousewheel, Navigation, Pagination, Parallax, Scrollbar, Thumbs, Virtual, Zoom,
import scrollLock from './functions/_scrollLock.js' // スクロールロック
import $ from 'jquery' // jQuery
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from 'gsap/all'

if (document.getElementById('page-top')) {
  pageTop()
}

function pageTop() {
  $('.js-faq-ques').click(function () {
    $(this).toggleClass('open')
    $(this).next('.js-faq-ans').slideToggle()
  })
  $('.js-flow-head').click(function () {
    $(this).toggleClass('open')
    $(this).next('.js-flow-text').slideToggle()
  })
  const target = $('#topRequirement').offset().top
  const end = $('#topFaq').offset().top
  const height = $('#topRequirement').innerHeight()
  const total = target + height
  const header = $('#header')
  $(window).scroll(function () {
    if ($(this).scrollTop() >= target && $(this).scrollTop() <= end) {
      header.addClass('color')
    } else {
      header.removeClass('color')
    }
  })

  //
  //  ##       #####     ###    ####    ##  ##     ##   ####
  //  ##      ##   ##   ## ##   ##  ##  ##  ####   ##  ##
  //  ##      ##   ##  ##   ##  ##  ##  ##  ##  ## ##  ##  ###
  //  ##      ##   ##  #######  ##  ##  ##  ##    ###  ##   ##
  //  ######   #####   ##   ##  ####    ##  ##     ##   ####
  //

  // クッキー

  var getCookieValue = function (key) {
    var val = document.cookie.match(new RegExp('(^|\\s)' + key + '=([^;]+)'))
    return !!val && unescape(val[2])
  }

  console.log(getCookieValue('viewed'))

  const openingBg = document.querySelector('.js-opening')
  const openingCountupShape = document.querySelector('.js-opening-countup-shape')
  const overlay = document.querySelector('#overlay')
  var video = document.getElementById('video')
  if (!getCookieValue('viewed')) {
    const opening = gsap.timeline()
    opening
      .add(() => {
        overlay.classList.add('active')
        scrollLock('body', '#overlay')
      })
      .to(openingBg, { opacity: 0, delay: 3.4, duration: 0.8 })
      .add(() => {
        overlay.classList.remove('active')
        scrollLock('body', '#overlay')
      }, '-=0.8')
      .add(() => {
        openingBg.classList.add('hide')
      })
      .add(() => {
        video.play()
      }, '-=0.8')
    if (navigator.cookieEnabled) {
      document.cookie = 'viewed=true max-age=86400'
    }
  } else {
    openingBg.classList.add('hide')
    video.play()
  }
  $(function () {
    $('.more-btn').on('click', function () {
      if ($(this).children().is('.open')) {
        $(this).html('<p class="close">閉じる</p>').addClass('close-btn')
        $(this).parent().removeClass('slide-up').addClass('slide-down')
      } else {
        $(this).html('<p class="open">もっと見る</p>').removeClass('close-btn')
        $(this).parent().removeClass('slide-down').addClass('slide-up')
      }
    })
  })
}
